/* Container for each speaker item */
.speaker-item {
  margin-bottom: 1rem;
}

/* Speaker name and position */
.speaker-info {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Container for speaker content (photo and bio) */
.speaker-content {
  overflow: hidden; /* Clear floats */
}

/* Speaker photo */
.speaker-photo {
  width: 240px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  float: right; /* Float to the right */
  margin-left: 1rem; /* Add spacing to the left of the photo */
  display: inline; /* Ensures it behaves like text for wrapping */
}

/* Speaker biography */
.speaker-bio {
  display: inline; /* Ensures text wraps next to floated image */
  overflow: hidden; /* Contains floated elements */
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .speaker-photo {
    width: 100%;   /* Full width on mobile */
    float: none;   /* Remove float to stack on mobile */
    margin: 1rem 0 0 0; /* Add spacing above on mobile */
  }
}

/* Make the header clickable */
.clickable-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
}

.break-header {
  background-color: var(--sodc-red) ; /* Shaded background for breaks */
  color: #fff;               /* Adjust text color if needed */
}

/* Keep the text content stacked vertically */
.header-content {
  flex: 1;
}

/* Icon styling for rotation */
.header-icon {
  font-size: 1rem;
  margin-left: auto;
  transition: transform 0.3s ease;
}

.header-icon.rotate {
  transform: rotate(180deg);
}