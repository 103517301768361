html {
    position: relative;
    min-height: 100%;
}

@media only screen and (min-width: 991px) {
    body {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 991px) {
    body {
        margin-bottom: 140px;
    }
}
