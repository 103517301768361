.form-warning-desc {
    color: #721c24 !important;
}

.form-warning-el {
    color: #721c24 !important;
    background: #f8d7da;
    border-color: #721c24;
    border-width: 1px 1px 1px 10px;
    border-style: solid;
    border-radius: .25rem;
}

.form-warning-captcha > div > div {
    /*color: #721c24 !important;*/
    /*background: #f8d7da;*/
    /*background: #E90036;*/
    border-color: #721c24;
    border-width: 1px 1px 1px 10px;
    border-style: solid;
    border-radius: .25rem;
    width: 313px !important;
}

/*.form-warning-captcha>div>div>div {
  opacity: .835;
}*/

.form-sub-display {
    padding: 1rem 1.25rem;
    background: rgba(0, 0, 0, .125);

    border-radius: .25rem;
    font-style: italic;
}

